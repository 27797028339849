* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html {
  font-family: "Inter", sans-serif;
  line-height: 1.7;
  font-size: 62.5%;
}
@media (max-width: 900px) {
  html {
    font-size: 60%;
  }
}
@media (max-width: 900px) {
  html {
    font-size: 60%;
  }
}
@media (max-width: 800px) {
  html {
    font-size: 57%;
  }
}
@media (max-width: 700px) {
  html {
    font-size: 55%;
  }
}
@media (max-width: 600px) {
  html {
    font-size: 52.5%;
    --input-height: 5rem !important;
  }
}
@media (max-width: 550px) {
  html {
    font-size: 50%;
  }
}
